<template>
  <iframe
    :src="`https://www.youtube.com/embed/${videoId}/?autoplay=${autoPlay}}&rel=0&mute=1&modestbranding=1&cc_load_policy=1`"
    frameborder="0"
    allowfullscreen
  ></iframe>
</template>

<script>
export default {
  props: {
    videoId: {
      type: String,
      required: true,
    },
    autoPlay: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>
